import { Col, Form, Input, InputNumber, Row, Slider } from 'antd';
import moment from 'moment';
import 'moment/locale/vi';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  FormEditor,
  FormInput,
  GeneralSelect,
  LanguageSelect,
  LevelSelect,
  PositionSelect,
} from '../../../../components/Form';
import PrioritySelect from '../../../../components/Form/Selects/PrioritySelect';
import TypeworkSelect from '../../../../components/Form/Selects/TypeworkSelect';
import {
  DEFAULT_MAX_LENGTH_DESCRIPTION,
  DEFAULT_PRIORITY,
  DETAIL_TITLE_FORM,
  QUANTITY_RULES,
} from '../../../../constants/requestPage';
import { API_RESPONSE_DATE_FORMAT } from '../../../../global/constants';
import RequestorSelect from './RequestorSelect';

import { hasPermission } from '../../../../utils/hasPermission';
import useFetchDetailRequest from '../useFetchDetailRequest';
import DeadlinePicker from './DeadlinePicker';
import { useFetchDeadlineDays, useFetchDefaultField } from './hooks';
import { safelyParse } from '../../../../utils/normalizeSendingData';
import CompanySelect from '../../../../components/Form/Selects/CompanySelect';
import CriteriaRequestForms from './hooks/CriteriaRequestForm';
import criteriaGroupApi from '../../../../api/criteriaGroupApi';
import MarkGuide from './MarkGuide';
import { Button } from '../../../../components/Buttons';
import { PlusCircleFilled } from '@ant-design/icons';
function RequestForm({ titleForm, form }) {
  const { t } = useTranslation();
  const { deadlineDays } = useFetchDeadlineDays();
  const { listPosition } = useFetchDefaultField();
  const location = useLocation();
  const [disableEditor, setDisableEditor] = useState(false);
  const userInfor = useSelector(state => state.auth.userInfor);
  const pathname = location.pathname.split('/').filter(x => x);
  const { detailRequest: requestFormInfo } = useFetchDetailRequest(pathname[2]);

  const criteriaData = requestFormInfo?.criterias?.map(item => [
    item.id,
    item.range,
  ]);

  let groupPosition = {};
  let firstPosition = {};
  const { initial, mode } = useSelector(state => state.drawer);
  const [currentData, setCurrentData] = useState({});

  const handlChangeName = useCallback(e => {
    setCurrentData(prev => ({ ...prev, name: e.target.value }));
  }, []);
  if (Object.keys(listPosition).length) {
    groupPosition = listPosition.reduce((groups, item) => {
      if (item.parent_title) {
        const group = groups[item.parent_title] || [];
        group.push(item);
        groups[item.parent_title] = group;
      }
      return groups;
    }, {});
    firstPosition = groupPosition[Object.keys(groupPosition)?.[0]]?.[0];
  }

  useEffect(() => {
    const detail = location.pathname.includes('/request/detail');
    if (
      // (detail && requestFormInfo.status === 1) ||
      // (detail && requestFormInfo.status === 3)
      (detail && requestFormInfo.status === 2) ||
      (detail && requestFormInfo.status === 0)
    ) {
      setDisableEditor(true);
    } else {
      setDisableEditor(false);
    }
  }, [location.pathname, requestFormInfo.status]);

  useEffect(() => {
    form.resetFields();
  }, [location]);

  const [idCriteriaGroup, setIdCriteriaGroup] = useState();

  const [criteriaTooltip, setCriteriaTooltip] = useState(true);

  const [criteriaByGroup, setCriteriaByGroup] = useState();
  useEffect(() => {
    idCriteriaGroup !== undefined
      ? setCriteriaTooltip(false)
      : setCriteriaTooltip(true);
  }, [criteriaByGroup]);

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const fetchDataById = async () => {
      try {
        const criteriaByGroupCriteraId = await criteriaGroupApi.getById(
          idCriteriaGroup,
        );

        setCriteriaByGroup(criteriaByGroupCriteraId?.data?.data);
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    };
    fetchDataById();
  }, [idCriteriaGroup, toggle, location.pathname]);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('add')) {
      form.setFieldsValue({
        requestor_id: userInfor.username,
        criterias: [
          [, 50],
          [, 50],
          [, 50],
          [, 50],
          [, 50],
          [, 50],
        ],
        // id_criteria_group: criteriaByGroup?.id,
      });
    } else {
      const levels = safelyParse(requestFormInfo.levels);
      form.setFieldsValue({
        ...requestFormInfo,
        position_id: requestFormInfo.position_id || undefined,
        levels: Array.isArray(levels) ? levels.map(level => level.id) : levels,
        typework_id: requestFormInfo.typework_id || undefined,
        deadline: requestFormInfo.deadline
          ? moment(requestFormInfo.deadline, API_RESPONSE_DATE_FORMAT)
          : undefined,
        languages: safelyParse(requestFormInfo.languages),
        id_criteria_group: requestFormInfo?.id_criteria_group,
        criterias: [...(criteriaData ?? []), [undefined, 50]],
      });
      setIdCriteriaGroup(requestFormInfo?.id_criteria_group);
    }
  }, [
    location.pathname,
    deadlineDays,
    requestFormInfo,
    form,
    userInfor,
    // criteriaByGroup,
  ]);

  const isDisabled = location.pathname.includes('detail');

  return (
    <Row gutter={{ sm: 24, xl: 48 }} style={{ marginTop: '20px' }}>
      {/* 1st */}
      <Col sm={24} xl={8}>
        <Row gutter={{ md: 12, lg: 24 }}>
          <Col sm={24} md={12} xl={12}>
            <RequestorSelect disabled={titleForm === DETAIL_TITLE_FORM} />
          </Col>
          <Col sm={24} md={12} xl={12}>
            <CompanySelect
              name="company_id"
              label={`${t('sidebar.company')}`}
              required
              disabled={disableEditor}
            />
          </Col>
          <Col sm={24} md={12} xl={12}>
            <PositionSelect
              name="position_id"
              label={`${t('request.position')}`}
              required
              disabled={disableEditor}
            />
          </Col>
          <Col sm={24} md={12} xl={12}>
            <LevelSelect
              required
              name="levels"
              mode="multiple"
              disabled={disableEditor}
              selectAll
              // form={form}
            />
          </Col>
          <Col sm={24} md={12} xl={12}>
            <Form.Item
              name="target"
              label={
                <span className="field--required">
                  {t('request.quantity')}(<span>*</span>)
                </span>
              }
              rules={QUANTITY_RULES}
            >
              <InputNumber
                type="number"
                min={1}
                max={99}
                className="w-100"
                disabled={disableEditor}
                placeholder={t('request.placeholderQuantity')}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xl={12}>
            <TypeworkSelect
              label={`${t('request.typework')}`}
              name="typework_id"
              required
              disabled={disableEditor}
            />
          </Col>
          <Col sm={24} md={12} xl={12}>
            <LanguageSelect
              name="languages"
              label={t('request.language')}
              mode="multiple"
              disabled={disableEditor}
            />
          </Col>
          <Col sm={24} md={12} xl={12}>
            <PrioritySelect
              name="priority"
              label={t('request.priority')}
              disabled={disableEditor}
            />
          </Col>

          <Row gutter={{ md: 12, lg: 24 }}>
            <Col sm={24} md={24}></Col>
          </Row>
          <Col sm={24} md={24}>
            <FormInput
              autoSize={{ minRows: 14 }}
              showCount={false}
              allowClear
              textArea
              max={DEFAULT_MAX_LENGTH_DESCRIPTION}
              placeholder={t('request.placeholderDescription')}
              label={t('request.requestorNote')}
              //label="Lưu ý của người yêu cầu"
              name="description"
              disabled={disableEditor}
            />
          </Col>
          <Col sm={24} md={24} xl={24}>
            <DeadlinePicker
              deadlineDays={deadlineDays}
              disabled={disableEditor}
            />
          </Col>
        </Row>
      </Col>
      {/* 2st */}
      <Col sm={24} xl={8}>
        <GeneralSelect
          label={t('sidebar.criteriaGroup')}
          name="id_criteria_group"
          valueKey="id"
          contentKey="name"
          api={criteriaGroupApi.getAll}
          onChange={value => setIdCriteriaGroup(value)}
          style={{ marginBottom: 20 }}
          disabled={disableEditor}
          required
        />
        <MarkGuide />
        <CriteriaRequestForms
          name="criterias"
          form={form}
          disableEditor={disableEditor}
          toggle={toggle}
          criteriaByGroup={criteriaByGroup?.criterias}
          idCriteriaGroup={idCriteriaGroup}
          setToggle={setToggle}
          criteriaTooltip={criteriaTooltip}
        />
      </Col>
      {/* 3st */}
      <Col sm={24} xl={8}>
        <FormEditor
          name="jd"
          label={t('request.jobDescription')}
          form={form}
          disabled={disableEditor}
          height="590"
        />

        <Button type="primary" disabled="true" icon={<PlusCircleFilled />}>
          GENERATE DESCRIPTION WITH CHAT GPT
        </Button>
      </Col>
    </Row>
  );
}

export default memo(RequestForm);
