import { Col, Form, message, Row } from 'antd';
import { breadcrumbsCv, requiredFields } from '../../../constants/newAddCv';

import { SaveOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cvApi from '../../../api/cvApi';
import uploadApi from '../../../api/uploadApi';
import LayoutBreadcrumb from '../../../components/Breadcrumb/LayoutBreadcrumb';
import { CancelWhiteButton } from '../../../components/Buttons/Buttons';
import {
  FormInput,
  GeneralSelect,
  LevelSelect,
  PositionSelect,
  SourceSelect,
  SubmitBtn,
  UserSelect,
} from '../../../components/Form';
import { useFetchUser } from '../../../components/Hooks/FetchApi';
import NoPermission from '../../../components/NoPermission';
import {
  rulesValidateEmail,
  validatePhoneNumber,
} from '../../../utils/validation';
import useCheckExist from '../hooks/useCheckExist';
import usePositionAndLevel from '../hooks/usePositionLevelAndRequest';
import AvatarShow from './AvatarShow';
import BirthdayPicker from './BirthdayPicker';
import UploadCv from './UploadCv';

export default function NewAdd(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfor } = useSelector(state => state.auth);
  const { cv: permission } = userInfor.permission;
  const [cvFilledForm, setCvFilledForm] = useState({});
  const [componentMouted, setComponentMounted] = useState(false);

  const { onChangeRequest, onChangePositionAndLevel, requests } =
    usePositionAndLevel({ form });

  const {
    onBlurBasicInfo,
    finishWithCheckExists,
    handleCheckFieldsExistOnchange,
  } = useCheckExist({
    form,
    sendDataToServer,
  });

  const { items: usersList } = useFetchUser();

  const [avatarList, setAvatarList] = useState([]);
  const [cvFile, setCvFile] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem('cvFilledForm');
  };

  const backToList = () => {
    handleClearSessionStorage();
    navigate('/cv');
  };

  async function sendDataToServer(values) {
    handleClearSessionStorage();
    const listFile = {};
    avatarList.map((e, i) => {
      listFile[`file${i}`] = e.file;
      return e;
    });
    setSubmitLoading(true);
    try {
      let images = [];
      let linkcv = '';
      if (avatarList && avatarList.length) {
        const urlImg = await uploadApi.postMutiple(listFile);
        images = urlImg.data.data;
      }
      if (cvFile) {
        const urlCv = await uploadApi.post(cvFile);
        linkcv = urlCv.data.data;
      }
      const item = {
        ...values,
        images: typeof images == 'object' ? images : [images],
        linkcv: linkcv ? linkcv : null,
      };

      for (let key in item) {
        if (typeof item[key] === 'string') item[key] = item[key].trim();
      }
      await cvApi.create(item);
      backToList();
      message.success(t('cv.createSuccess'));
      setSubmitLoading(false);
    } catch (e) {
      setSubmitLoading(false);
      message.error(e.message);
    }
  }

  const handleFormFieldsChange = debounce((props, fields) => {
    setCvFilledForm({
      ...cvFilledForm,
      [props[0].name[0]]: props[0].value,
    });
  }, 500);

  const saveAndUsingDataInSession = () => {
    if (!componentMouted) {
      const dataSaved = JSON.parse(
        window.sessionStorage.getItem('cvFilledForm'),
      );
      if (dataSaved) {
        setCvFilledForm(dataSaved);
        form.setFieldsValue({
          ...form.getFieldsValue(),
          ...dataSaved,
        });
      }
      setComponentMounted(!componentMouted);
    } else {
      const dataToSave = JSON.stringify(cvFilledForm);
      window.sessionStorage.setItem('cvFilledForm', dataToSave);
    }
  };

  useEffect(() => {
    saveAndUsingDataInSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvFilledForm]);

  return permission.add ? (
    <Form
      form={form}
      layout="vertical"
      onFinish={finishWithCheckExists}
      className="standard-form"
      onFieldsChange={handleFormFieldsChange}
    >
      <LayoutBreadcrumb
        breadcrumbNameMap={breadcrumbsCv}
        extra={[
          <SubmitBtn
            form={form}
            loading={submitLoading}
            requiredFields={requiredFields}
            icon={<SaveOutlined />}
          >
            {t('common.save')}
          </SubmitBtn>,

          <CancelWhiteButton onClick={backToList}>
            {t('common.cancel')}
          </CancelWhiteButton>,
        ]}
        component={
          <div className="addCv">
            <Row
              gutter={{ sm: 24 }}
              justify="center"
              className="create-cv-content"
            >
              {/* Avatar show */}
              <Col sm={10} xl={6} xxl={5} className="show">
                <AvatarShow
                  avatarList={avatarList}
                  setAvatarList={setAvatarList}
                />
              </Col>
              {/* Left Form */}
              <Col sm={24} md={14} xl={9} xxl={9}>
                <FormInput
                  name="fullname"
                  label={t('user.fullname')}
                  min={3}
                  required
                  onBlur={onBlurBasicInfo}
                  placeholder={t('user.nameplace')}
                />
                <PositionSelect
                  name="position_id"
                  label={t('user.position')}
                  required
                  onChange={e => {
                    onChangePositionAndLevel(e);
                    onBlurBasicInfo();
                  }}
                />
                <LevelSelect
                  name="level_id"
                  required
                  onBlur={onBlurBasicInfo}
                  onChange={e => {
                    onChangePositionAndLevel(e);
                    onBlurBasicInfo();
                  }}
                />
                <GeneralSelect
                  name="request_id"
                  label={t('updateCv.request')}
                  required
                  valueKey="id"
                  contentKey="content"
                  onChange={onChangeRequest}
                  fetchedItems={requests}
                />
                <UserSelect
                  name="reviewer_id"
                  label={t('updateCv.reviewer')}
                  fetchedItems={usersList}
                  withFullName
                />
                <UserSelect
                  name="interviewer_id"
                  label={t('updateCv.interviewer')}
                  fetchedItems={usersList}
                  withFullName
                />
                <UserSelect
                  name="assignee_id"
                  label={t('common.assignee')}
                  withFullName
                  required
                  fetchedItems={usersList}
                />
                <div className="upload-file">
                  <UploadCv cvFile={cvFile} setCvFile={setCvFile} />
                </div>
              </Col>
              {/* Right form */}
              <Col sm={24} xl={9} xxl={9}>
                <FormInput
                  name="email"
                  label={t('user.email')}
                  placeholder={t('user.emailplace')}
                  rules={rulesValidateEmail()}
                  onChange={() => {
                    handleCheckFieldsExistOnchange('email');
                  }}
                />
                <FormInput
                  name="mobile"
                  label={t('user.mobile')}
                  placeholder={t('user.mobileplace')}
                  type="phone"
                  rules={validatePhoneNumber()}
                  onChange={() => {
                    handleCheckFieldsExistOnchange('mobile');
                  }}
                />
                <BirthdayPicker />
                <FormInput
                  name="address"
                  label={t('updateCv.address')}
                  min={3}
                  placeholder={t('updateCv.addressPlaceholder')}
                />
                <SourceSelect
                  name="source_id"
                  label={t('user.source')}
                  required
                />
                <FormInput
                  textArea
                  name="description"
                  label={t('user.description')}
                  rows={4}
                />
              </Col>
            </Row>
          </div>
        }
      />
    </Form>
  ) : (
    <NoPermission />
  );
}
