import { Slider, Tooltip } from 'antd';
import React from 'react';
import './style.scss';

const MarkGuide = () => {
  const marks = {
    0: {
      style: {
        color: '#ff0000',
      },
      label: <strong>0</strong>,
    },
    40: {
      style: {
        color: '#ff0000',
      },
      label: <strong>50</strong>,
    },
    55: {
      style: {
        color: '#ffa500',
      },
      label: <strong>55</strong>,
    },
    70: {
      style: {
        color: '#ffa500',
      },
      label: <strong>70</strong>,
    },
    85: {
      style: {
        color: '#a7e13e',
      },
      label: <strong>85</strong>,
    },
    100: {
      style: {
        color: '#a7e13e',
      },
      label: <strong style={{ marginRight: '20px' }}>100</strong>,
    },
  };

  return (
    <>
      <div style={{ marginBottom: '60px' }}></div>
      <Slider
        // disabled='true'
        className="slider-example"
        min={0}
        max={100}
        step={1}
        defaultValue={100}
        style={{
          width: 510,
          marginBottom: 60,
        }}
        marks={marks}
        railStyle={{
          backgroundColor: 'orange',
        }}
      />
      <ul class="range-title">
        <li id="range-title-poor">Poor/ Weak</li>

        <li id="range-title-ba">
          <Tooltip placement="top" title="Below Average">
            Below Average
          </Tooltip>
        </li>
        <li id="range-title-av">Average</li>

        <li id="range-title-good">Good</li>
        <li id="range-title-excellent">Excellent</li>
        <li id="line-1">
          <div class="line"></div>
        </li>
        <li id="line-2">
          <div class="line"></div>
        </li>
      </ul>
    </>
  );
};

export default MarkGuide;
