import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer } from '../../../../components/Drawer/slice/drawer';
import NoPermission from '../../../../components/NoPermission';
import { Header, TableMain, useTable } from '../../../../components/Table';
import BasicTableFilter from './component/BasicTableFilter';
import ModalForm from './component/ModalForm';
import { cols } from './constants';

function BasicTable({
  api,
  i18n,
  config,
  permission,
  fields,
  columns,
  requiredFields,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: api.getAll,
    });

  const Filter = () => (
    <BasicTableFilter filter={filter} setFilter={setFilter} />
  );

  const title = i18n.mainTitle;

  const handleClickRow = record => ({
    onClick() {
      const titleLower = title.toLowerCase();
      dispatch(
        openDrawer({
          initial: record,
          modeText: {
            btn: t('common.edit'),
            title: `${t('common.edit')} ${titleLower}`,
          },
        }),
      );
    },
  });

  return (
    <>
      <ModalForm
        addApi={api.add}
        editApi={api.edit}
        fetchData={fetchData}
        i18n={i18n}
        fields={fields}
        setFilter={setFilter}
        requiredFields={requiredFields}
      />
      <Header title={title} addPermission={permission.add} />
      <Filter />
      <TableMain
        cols={columns}
        nth
        titleLabel={title}
        items={items}
        title={title}
        fetchData={fetchData}
        deleteApi={api.remove}
        getApi={api.getAll}
        deleteManyApi={api.removeMany}
        deletePermission={permission.delete}
        editPermission={permission.edit}
        filter={filter}
        Filter={Filter}
        setFilter={setFilter}
        totalRecord={totalRecord}
        loadingTable={loadingTable}
        excelName={config.excelSheet}
        onRow={handleClickRow}
      />
    </>
  );
}

export default function BasicTableIndex({
  columns = cols,
  requiredFields,
  ...props
}) {
  const permission = useSelector(
    state => state.auth.userInfor.permission[props.config.permissionStateName],
  );
  return permission.view ? (
    <BasicTable
      columns={columns}
      {...props}
      permission={permission}
      requiredFields={requiredFields}
    />
  ) : (
    <NoPermission />
  );
}
