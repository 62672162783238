import { Form, message } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GeneralDrawer from '../../../../components/Drawer/GeneralDrawer';
import {
  FormInput,
  FormItem,
  FormRadio,
  LevelSelect,
  UserSelect,
} from '../../../../components/Form';
import DepartmentSelect from '../../../../components/Form/Selects/DepartmentSelect';
import { DEFAULT_STATUS } from '../../../../constants';
import { status } from '../../Mail/constants';
import { safetyParse } from '../constant';
import { requiredFields } from '../constants';
import RedemptionSwitch from './RedemptionSwitch';
import useCurrentBreakPoint from '../../../../hooks/useCurrentBreakPoint';

export default function ModalForm(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { currentBreakpoint } = useCurrentBreakPoint();

  const { initial, mode } = useSelector(state => state.drawer);

  const fillData = () => {
    form.resetFields();
    const init =
      mode === 'add' ? { point_status: 1, status: DEFAULT_STATUS } : initial;
    form.setFieldsValue({
      ...init,
      requestor: safetyParse(init.requestor),
      user_cvs: safetyParse(init.user_cvs),
      levels: safetyParse(init.levels),
    });
  };

  const catchCallback = error => {
    if (error.data.message) {
      const msg = error.data.message;
      if (msg.includes('exists')) {
        message.error(t('position.positionExists'));
      } else {
        message.error(msg);
      }
    }
  };

  const modalWidth = useMemo(() => {
    if (currentBreakpoint === 'sm') {
      return '80%';
    } else if (['md', 'lg'].includes(currentBreakpoint)) {
      return '60%';
    } else {
      return '40%';
    }
  }, [currentBreakpoint]);

  return (
    <GeneralDrawer
      {...props}
      fillData={fillData}
      form={form}
      catchCallback={catchCallback}
      className="position-form"
      fullscreenClassName="table-fullscreen"
      requiredFields={requiredFields}
      modal
      width={modalWidth}
    >
      <div className="form-group">
        <FormInput
          name="title"
          label={t('position.position')}
          required
          max={50}
          min={3}
          placeholder={t('position.titlePlaceholder')}
        />
        <DepartmentSelect
          label={t('position.department')}
          name="parent_id"
          required
        />
      </div>
      <div className="form-group">
        <FormInput
          name="key"
          label={t('position.keyField')}
          required
          max={5}
          placeholder={t('position.titlePlaceholder')}
        />
        <FormInput
          inputType="number"
          name="rank"
          label={t('position.rank')}
          inputNumber
          minNumber={0}
          required
          max={4}
          placeholder={t('position.placeholderRank')}
        />
      </div>
      <UserSelect label={t('position.manager')} name="manager_id" required />
      <UserSelect
        label={t('position.requestor')}
        name="requestor"
        required
        mode="multiple"
      />
      <UserSelect
        label={t('position.allowedViewCvPeople')}
        name="user_cvs"
        required
        mode="multiple"
      />
      <LevelSelect name="levels" mode="multiple" required />
      <FormInput
        label={t('position.description')}
        name="description"
        textArea
      />
      <FormItem
        label={t('sidebar.redemption')}
        name="point_status"
        valuePropName="checked"
        className="redemption-item"
      >
        <RedemptionSwitch />
      </FormItem>
      <FormRadio label={t('common.status')} name="status" items={status} />
    </GeneralDrawer>
  );
}
