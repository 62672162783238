import axiosClient from './axiosClient';
import { REVIEW_CRITERIAS } from '../constants/api';

const reviewCriterias = {
  post(data) {
    return axiosClient.post(REVIEW_CRITERIAS, data);
  },
  getAll(params) {
    return axiosClient.get(REVIEW_CRITERIAS, { params });
  },
  getById(id) {
    return axiosClient.get(REVIEW_CRITERIAS + `/${id}`);
  },
};
export default reviewCriterias;
