import { DatePicker } from 'antd';
import React from 'react';
import { DISPLAY_FORMAT } from '../../global/constants';
import FormItem from './FormItem';
import i18n from '../../translation/i18n';
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';

export default function FormDatePicker(props) {
  const {
    name,
    required,
    label,
    style,
    className,
    disabled,
    placeholder,
    hasFeedBack,
    ...rest
  } = props;
  const lowerLabel = label.toLowerCase();

  const formItemProps = {
    name,
    required,
    label,
    style,
    className,
    hasFeedBack,
    type: 'select',
  };
  const { t } = useTranslation();
  const datePickerProps = {
    disabled,
    getPopupContainer: triggerNode => triggerNode.parentNode,
  };
  datePickerProps.placeholder =
    placeholder || `${t('common.select')} ${lowerLabel}`;
  return (
    <FormItem {...formItemProps}>
      <DatePicker
        {...datePickerProps}
        {...rest}
        className="w-100"
        format={DISPLAY_FORMAT}
      />
    </FormItem>
  );
}
