import React from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';
import i18n from '../../../translation/i18n';
import {
  getPreviousMonth,
  getThisMonth,
  getTwoPreviousMonth,
} from '../../../utils/getPreviousMonth';
// import locale from 'antd/es/date-picker/locale/vi_VN';
import locale from 'antd/lib/date-picker/locale/en_US';
import { DISPLAY_FORMAT } from '../../../global/constants';

const { RangePicker: RangePickerAntd } = DatePicker;

export const RangePicker = ({ name, label, disableFuture, ...props }) => {
  const newLocale = locale;
  newLocale.lang.rangePlaceholder = [i18n.t('time.from'), i18n.t('time.to')];
  return (
    <Form.Item name={name} label={label}>
      <RangePickerAntd
        locale={newLocale}
        format={DISPLAY_FORMAT}
        ranges={{
          [i18n.t('time.today')]: [moment(), moment()],
          [i18n.t('time.thisMonth')]: getThisMonth(disableFuture),
          [i18n.t('time.previousMouth')]: [
            getPreviousMonth(new Date()),

            getPreviousMonth(new Date()).endOf('month'),
          ],
          [i18n.t('time.twoPreviousMouth')]: [
            getTwoPreviousMonth(new Date()),
            getPreviousMonth(new Date()).endOf('month'),
          ],
        }}
        className="w-100"
        getPopupContainer={trigger => trigger.parentNode}
        disabledDate={date =>
          disableFuture ? date > moment().endOf('day') : false
        }
        {...props}
      />
    </Form.Item>
  );
};
