export const AUTH_URL = 'v1/login';
export const REFRESH_URL = 'v1/refresh';
export const API_LEVEL = 'v1/level';
export const POSITION_URL = 'v1/positions';
export const CRITERIA_URL = 'v1/criteria';
export const CRITERIA_ELEMENT_URL = 'v1/criteria_elements';
export const CRITERIA_GROUP_URL = 'v1/criteria_group';
export const CRITERIA_SET_URL = 'v1/template_request';
export const CRITERIA_CV_URL = '/v1/criteria_cv';
export const COMPANY_URL = 'v1/company';
export const DEPARTMENT_URL = 'v1/department';
export const SOURCE_API = '/v1/source';
export const USER_URL = 'v1/users';
export const ROLE_URL = 'v1/role';
export const DASHBOARD_API = '/v1/dashboard';
export const DASHBOARD_CV_URL = '/v1/dashboard_cv';
export const POSITION_API = '/v1/positions';
export const LEVEL_API = '/v1/level';
export const LANG_URL = '/v1/language';
export const TYPE_WORK_URL = '/v1/type_work';
export const PLAN_URL = '/v1/plan';
export const REQUEST_URL = '/v1/request';
export const CV_URL = '/v1/cv';
export const CV_FAVORITE_URL = '/v1/cv_favorite';
export const REVIEW_PHYSIOGNOMY1_URL = '/v1/review_physiognomy1';
export const REVIEW_PHYSIOGNOMY2_URL = '/v1/review_physiognomy2';
export const PREOFFER_URL = '/v1/cv_preoffer';
export const OFFER_URL = '/v1/cv_offer';
export const ONBOARD_URL = '/v1/cv_onboard';
export const REVIEW_CV_URL = '/v1/review_cv';
export const REVIEW_HR_URL = '/v1/review_hr';
export const REVIEW_CRITERIAS = '/v1/reviews';
export const CV_HISTORY = '/v1/cv_history';
export const EMAIL_HISTORY = '/v1/email_history';
export const EMAIL_TEMPLATE = '/v1/email';
export const SEND_MAIL = '/v1/sendmail';
export const PERMISSION_URL = '/v1/permission';
export const TO_INTERVIEW = '/v1/to_interview';
export const HR_INTERVIEW = '/v1/interview_hr';
export const TECH_INTERVIEW = '/v1/interview_tech';
export const PROBATION_URL = '/v1/cv_probation';
export const USERS_URL = '/v1/users';
export const SETTING_URL = '/v1/setting';
export const UPLOAD_URL = '/v1/upload';
export const IMPORT_URL = 'v1/import';
export const EMAIL_TEMPLATES_URL = 'v1/email';
export const EMAIL_HISTORY_URL = 'v1/email_history';
export const RELEASE_URL = 'v1/release_note';
export const POINT_URL = 'v1/position_point';
export const HISTORY_SEARCH_API_URL = 'v1/history_search?site=';
